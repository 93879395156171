import Vue from "vue";
import Vuex from "vuex";
import result from "./modules/result";
import bannerStore from "./modules/bannerStore";
import main_result6 from "./modules/main_result6";
import main_result12 from "./modules/main_result12";
import contactStore from "./modules/contactStore";
import downLoader from "./modules/downLoader";
import grobal from "./modules/grobal";
import checkLottery6 from "./modules/checkLottery6";
import checkLottery12 from "./modules/checkLottery12";
import promo from "./modules/promo";
import gamePluzzle from "./modules/gamePluzzle";
import dreamSlider from "./modules/dreamSlider";
import luckyperson from "./modules/luckyperson";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    luckyperson,
    dreamSlider,
    gamePluzzle,
    promo,
    downLoader,
    main_result6,
    main_result12,
    contactStore,
    result,
    bannerStore,
    checkLottery6,
    checkLottery12,
    grobal,
  },
});

export default store;
