import axios from "axios";
import { genSignature } from "@/plugins/gen";
const state = () => ({
  result12: [],
  listZodiac: [],
});

const mutations = {
  SET_SELECTED_ITEM(state, item) {
    state.result12 = item;
  },
  SET_ZODIAC(state, item) {
    state.listZodiac = item;
  },
};

const actions = {
  MainResult({ commit }) {
    let mySignature;
    genSignature("/api/v1/website/laolot/WinPrizeHistory?type=2").then(
      (res) => {
        mySignature = res;
        // Signature------------------------------------------------------------------------------------------------------------>
        // console.log("main_result Sg: ", mySignature);
        return new Promise((resolve, reject) => {
          axios
            .get(`/api/website/laolot/WinPrizeHistory?type=2`, {
              headers: {
                "X-SIGNATURE-APP": mySignature,
              },
            })
            .then((resp) => {
              console.log("resp", resp);

              // respon Api--------------------------------------------------------------------------------------------------->
              // console.log("resp", resp);
              commit("SET_SELECTED_ITEM", resp.data.resultData);
              commit("SET_ZODIAC", resp.data.resultData);
              resolve(resp);
            })
            .catch((err) => {
              reject(err);
            });
        });
      }
    );
  },
  async fetchBannerData() {
    try {
      const mySignature = await genSignature(
        "/api/v1/website/laolot/WinPrizeHistory?type=2"
      );
      let response = await axios.get(
        "/api/website/laolot/WinPrizeHistory?type=2",
        {
          headers: {
            "X-SIGNATURE-APP": mySignature,
          },
        }
      );
      this.result12 = response.data.resultData;
    } catch (error) {
      console.error("Error!", error);
    }
  },
};

const getters = {
  result12: (state) => state.result12,
  roundSix: (state) => state.roundSix,
  listZodiac: (state) => state.listZodiac,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
