// import Vue from 'vue'
// import App from './App.vue'
// import './registerServiceWorker'
// import router from './router'
// import store from './store/main_store'
// import vuetify from './plugins/vuetify'
// import NwImg from 'nw-img-vue';
// Vue.config.productionTip = false
// Vue.use(NwImg);
// new Vue({
//   router,
//   store,
//   vuetify,
//   render: h => h(App)
// }).$mount('#app')
import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store/main_store";
import vuetify from "./plugins/vuetify";
import NwImg from "nw-img-vue";
import axios from "axios";
import VueAxios from "vue-axios";
import GlobalPlugin from './plugins/useGlobal.js';
import QrcodeVue from "qrcode.vue";  // Import qrcode.vue

Vue.config.productionTip = false;

Vue.use(NwImg);
Vue.use(VueAxios, axios);
Vue.use(GlobalPlugin);
Vue.component('QrcodeVue', QrcodeVue);  // Register qrcode.vue globally

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
