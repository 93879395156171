const CryptoJS = require("crypto-js");
const genSignature = async (data) => {
  const signature = CryptoJS.AES.encrypt(
    JSON.stringify(data) + process.env.VUE_APP_SIGNATURE_VERIFY,
    process.env.VUE_APP_SIGNATURE_VERIFY
  ).toString();
  return signature;
};

export { genSignature };
