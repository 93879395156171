<template>
  <v-app>
    <v-main>
      <NavBar v-if="!isNotFoundPage" />
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import NavBar from "./components/NavBar.vue";

export default {
  name: "App",
  components: {
    NavBar,
  },
  data: () => ({
    //
  }),
  computed: {
    // Check if the current route is 404
    isNotFoundPage() {
      return this.$route.matched.some(route => route.path === '*');
    }
  }
};
</script>

<style></style>
