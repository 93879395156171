import moment from "moment";
let REGEX_NUMBER = /^[0-9]*$/;

const formatNumber = (value) => {
  if (!value) return "0";
  value = Number(value).toFixed(0);
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
const formatNumber2 = (value) => {
  if (!value) return "0.00";
  value = Number(value).toFixed(2);
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
const formatdate = (date) => {
  if (date) {
    return (date = moment(String(date)).format("YYYY-MM-DD"));
  }
};
const formatdate2 = (date) => {
  if (date) {
    return (date = moment.utc(String(date)).format("DD/MM/YYYY"));
  }
};
const formatDateTime = (date) => {
  if (date) {
    return (date = moment.utc(String(date)).format("DD/MM/YYYY HH:mm:ss"));
  }
};
const formatTimeDate = (date) => {
  if (date) {
    return (date = moment.utc(String(date)).format("HH:mm DD/MM/YYYY "));
  }
};


const formatDateTime2 = (date) => {
  if (date) {
    return (date = moment(date).format("DD MMM YYYY H:mm:ss"));
  }
};
const onlyNumber = (e, max) => {
  if (!REGEX_NUMBER.test(e.key) || e.target.value.length == max) {
    return e.preventDefault();
  }
};
export default {
  install(Vue) {
    Vue.prototype.$formatNumber = formatNumber;
    Vue.prototype.$formatNumber2 = formatNumber2;
    Vue.prototype.$formatdate = formatdate;
    Vue.prototype.$formatdate2 = formatdate2;
    Vue.prototype.$formatDateTime = formatDateTime;
    Vue.prototype.$formatTimeDate = formatTimeDate;
    Vue.prototype.$formatDateTime2 = formatDateTime2;
    Vue.prototype.$onlyNumber = onlyNumber;
  }
};
