import axios from "axios";
import { genSignature } from "@/plugins/gen";
const state = () => ({
  contactList: [],
  socialList: [],
});

const mutations = {
  SET_CONTACT(state, item) {
    state.contactList = item;
  },
  SET_SOCAIL(state, item) {
    state.socialList = item;
  },
};

const actions = {
  GetContact({ commit }) {
    let mySignature;
    genSignature("/api/v1/website/ourcompany/view").then((res) => {
      mySignature = res;
      // Signature------------------------------------------------------------------------------------------------------------>
      // console.log("Signature: ",mySignature);
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/website/ourcompany/view`, {
            headers: {
              "X-SIGNATURE-APP": mySignature,
            },
          })
          .then((resp) => {
            // respon Api--------------------------------------------------------------------------------------------------->
            commit("SET_CONTACT", resp.data.resultData);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    });
  },
  async GetSocial({ commit }) {
    let mySignature;
    genSignature("/api/v1/website/contactsocial/view").then((res) => {
      mySignature = res;
      // Signature------------------------------------------------------------------------------------------------------------>
      // console.log("Signature: ",mySignature);
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/website/contactsocial/view`, {
            headers: {
              "X-SIGNATURE-APP": mySignature,
            },
          })
          .then((resp) => {
            // respon Api--------------------------------------------------------------------------------------------------->
            console.log("test", resp);
            commit("SET_SOCAIL", resp.data.resultData);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    });
  },
};

const getters = {
  contactList: (state) => state.contactList,
  socialList: (state) => state.socialList,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
